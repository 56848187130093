<template>
  <div class="about">
	<el-row>
  <el-button>默认按钮</el-button>
  <el-button type="primary">主要按钮</el-button>
  <el-button type="success">成功按钮</el-button>
  <el-button type="info">信息按钮</el-button>
  <el-button type="warning">警告按钮</el-button>
  <el-button type="danger">危险按钮</el-button>
</el-row>
    <h1>This is an about page</h1>
	<el-input></el-input>
	<el-button>111</el-button>
	<el-select v-model="value" class="m-2" placeholder="Select" size="large">
	    <el-option
	      v-for="item in options"
	      :key="item.value"
	      :label="item.label"
	      :value="item.value"
	    />
	  </el-select>
	  <el-time-select
	    v-model="value"
	    :picker-options="{
	      start: '08:30',
	      step: '00:15',
	      end: '18:30'
	    }"
	    placeholder="选择时间">
	  </el-time-select>
	  {{$store.state.num}}
  <el-carousel indicator-position="outside">
    <el-carousel-item v-for="item in 4" :key="item">
      <h3>{{ item }}</h3>
    </el-carousel-item>
  </el-carousel>
  </div>
</template>
<script>
	export default {
	  name: 'HelloWorld',
	  props: {
	    msg: String
	  },
	  data(){
		  return{
			options:[
			  {value: 'Option1',label: 'Option1',},
			  {value: 'Option2',label: 'Option2',},
			],
			value: ''
		  }
	  },
	  created() {
	  	
	  },
	  methods:{
		  
		  
	  }
	}
	
</script>

<style scoped>

</style>
